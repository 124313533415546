<template>
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <span class="navbar-brand brand-logo mr-5">
        <img src="@/assets/images/suppliercheck-logo.png" class="mr-2" alt="logo" />
      </span>
    </div>
       

    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <div class="btn-group">
          <i class="ti-user ml-2 mx-0"></i>
          {{ $store.state.authed_xero.organisation_name }}
      </div>
    </div>

  </nav>
</template>

<script>
import {mapGetters} from 'vuex'
import api from "@/api.js";
//import $ from "jquery";
export default {
  name: "Header",
  data: function() {
    return {
      isLoading: false,
      share_link: "Loading your link...",
      password_reset: "",
      login: {
        email: "",
        password: ""
      },
      reset: {
        email: ""
      }
    };
  },
  methods: {
    clear_current_org() {
      this.$store.dispatch('deselect_current_organisation')
    },
    async refresh_xero() {
      this.isLoading = true;
      await this.$store.dispatch("resync_and_reload_contacts_report");
      this.isLoading = false;
    },
    async reload_contacts_report() {
      await this.$store.dispatch("reload_contacts_report");
    },
    activateSharingLink() {
      api
        .get(`integrations/authed-xero/` + this.current_xero_organisation + `/@@sharing-link`)
        .then(
          (resp) => (this.share_link = resp.data.link + window.location.hash)
        );
    },
  },
  props: {
    is_app: Boolean
  },
  computed: {
    ...mapGetters(["current_xero_organisation", "is_view_only_user"]),

    finished_syncing() {
      return this.$store.getters.finished_syncing;
    },
    freshReportAvailable() {
      if (!this.$store.state.contacts_report.report_id) {
        return false;
      }
      if (!this.$store.state.authed_xero.current_report_id) {
        return false;
      }
      return (
        this.$store.state.authed_xero.current_report_id !=
        this.$store.state.contacts_report.report_id
      );
    },
    clientDashUrl() {
      const params = (new URL(document.location)).searchParams;
      const clientDash = params.get('clientdash');
      if (clientDash) {
        return 'https://evenly.com.au/paypredict/' + clientDash
      }
      return null
    },
  }
};
</script>

<style scoped lang="scss">
$background: #fff;
$color: #333;
$focus-background: #eee;
$focus-color: #4d82ad;


ul.nav {
  background: $background;
  list-style: none;
  margin: 0;
  padding-left: 0;

  li {
    color: $color;
    background: $background;
    display: block;
    float: right;
    // padding: 5px 10px;
    // margin-right: 10px;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
    border-radius: 3px;
    &.thin {
      padding: 5px 0px;
      hr {
        margin: 0;
      }
      &:hover,
      &:focus-within {
        background: $background;
        cursor: default;
      }
    }
    a {
      color: $color;
      display: block;
      width: 100%;
      &:hover {
        color: $focus-color;
      }
    }
    &:hover,
    &:focus-within {
      background: $focus-background;
      cursor: pointer;
    }
    &:focus-within a {
      outline: none;
    }
    ul {
      text-align: left;
      min-width: 200px;
      background: $background;
      visibility: hidden;
      opacity: 0;
      min-width: 8rem;
      position: absolute;
      transition: all 0.5s ease;
      margin-top: 2px;
      padding-top: 5px;
      right: 0;
      display: none;
      border-radius: 3px;
      -webkit-box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
      li {
        clear: both;
        width: 100%;
        min-width: 200px;
      }
    }
    &:hover > ul,
    &:focus-within > ul,
    ul:hover,
    ul:focus {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }
}

// header

.loading a {
  text-decoration: underline;
}
</style>
