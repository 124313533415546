import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api.js'

Vue.use(Vuex)


function _get_signup_type() {
    // Return whether a business or advisor signup
    var params = new URLSearchParams(self.location.search)
    var type = params.get('type') || localStorage.getItem('signup_type')

    // Ensure type is valid
    if (!['business', 'advisor', 'referred_by_advisor', 'referred_by_business'].includes(type)) {
        type = 'business'
    }

    // Store in localstorage so preserved after redirect to xero
    localStorage.setItem('signup_type', type)

    return type
}


function _get_signup_referral_id() {
    // Return referral id if any
    var params = new URLSearchParams(self.location.search)
    var rid = params.get('refer_id') || localStorage.getItem('refer_id')

    // Store in localstorage so preserved after redirect to xero
    if (rid) { // Don't write "null" if null
        localStorage.setItem('refer_id', rid)
    }

    return rid
}


export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        application_fault: null,
        session_dirty: true,
        pyramid_user_uuid: null,
        session_info: {
            xero_any_org_is_connected: false,
            is_user_logged_in: false,
            csrf_token: null,
            crisko_user_info: {
                user_uuid: null,
                name: null,
                email: null,
                role: null,
                newsletter_optin: null,
                membership_type: null,
                membership_id: null,
                referral_client_id: null,
                receive_alerts: null,
                automated_reminders: null,
                payment_details: null,
            },
            connected_orgs: [],
            xero_share_org_id: false,
        },
        share_organisation: {
            xero_authed_organisation_id: null,
            share_secret: null,
        },
        authed_xero: {
            organisation_name: null,
            sync_status: null,
            current_report_id: null,
        },
        contacts_report_dirty: false,
        contacts_report: {
            report_id: null,
            timestamp: null,
            totals: {
                amount_overdue: null,
                non_regular_payer_rate: null,
            },
            items: [],
        },
        signup_type: _get_signup_type(),
        signup_referral_id: _get_signup_referral_id(),
        is_fetching_contacts_report: true, // initially we expect new load,
        connected_accounts: []
    },

    getters: {
        logged_in(state) {
            return state.session_info.is_user_logged_in || state.session_info.xero_share_org_id != null
        },
        org_loading_complete(state) {
            return state.authed_xero.current_report_id != null
        },
        connected_orgs(state) {
            return state.session_info.connected_orgs
        },
        current_xero_organisation_data(state, getters) {
            for (const org_data of getters.connected_orgs) {
                if (org_data.id == getters.current_xero_organisation){
                    return org_data
                }
            }
            return null
        },
        finished_syncing(state) {
            if (state.authed_xero.organisation_name === null) {
                // Haven't synced at all yet
                return false
            }
            // True if no sync is currently occuring
            return state.authed_xero.sync_status === null
        },

        crisko_user_setup_incomplete(state) {
            return !state.session_info.share_user && state.session_info.crisko_user_info.newsletter_optin === null;
        },
        xero_is_connected(state) {
            return state.session_info.xero_any_org_is_connected;
        },
        share_user(state) {
            return state.session_info.xero_share_org_id != null;
        },
        current_xero_organisation(state) {
            if (state.share_organisation.xero_authed_organisation_id) {
                return state.share_organisation.xero_authed_organisation_id
            }
            const params = new URLSearchParams(window.location.search)
            return params.get('orgId')
        },
        is_view_only_user(state) {
            return state.share_organisation.xero_authed_organisation_id != null;
        },
        connected_accounts(state) {
            return state.connected_accounts
        }
    },

    mutations: {

        set_session_dirty(state) {
            state.session_dirty = true;
        },

        set_session_info(state, data) {
            // NOTE Avoid adding/removing keys from state while processing response
            for (const key of Object.keys(state.session_info)) {
                if (key !== 'crisko_user_info') {
                    state.session_info[key] = data[key]
                }
            }
            for (const key of Object.keys(state.session_info.crisko_user_info)) {
                // Set all keys to null if whole crisko_user_info is null
                const value = data.crisko_user_info ? data.crisko_user_info[key] : null
                state.session_info.crisko_user_info[key] = value
            }
            state.session_dirty = false;
        },

        set_application_fault(state, message) {
            state.application_fault = message;
        },

        set_authed_xero(state, data) {
            for (const key of Object.keys(state.authed_xero)) {
                state.authed_xero[key] = data[key]
            }
        },

        set_contacts_report(state, data) {
            for (const key of Object.keys(data.totals)) {
                state.contacts_report.totals[key] = data.totals[key]
            }
            state.contacts_report.report_id = data.report_id
            state.contacts_report.timestamp = data.timestamp
            state.contacts_report.items = data.items
            state.contacts_report_dirty = false;
        },

        set_share_organisation(state, params) {
            state.share_organisation.xero_authed_organisation_id = params.get("orgId")
            state.share_organisation.share_secret = params.get("token")
        },

        set_is_fetching_contacts_report(state, value) {
            state.is_fetching_contacts_report = value
        },

        set_user_connected_accounts(state, list) {
            state.connected_accounts = list.filter(({ account }) => account.payment_plan == 'supplypredict')
        }
    },

    actions: {

        application_fault(context, message) {
            context.commit('set_application_fault', message)
        },

        deselect_current_organisation(context) {
            const url = new URL(window.location.href)
            url.searchParams.delete('orgId')
            window.location.href = url.toString()
        },

        async disconnect_xero_org(context) {
            await api.get('integrations/authed-xero/' + context.getters.current_xero_organisation + '/@@disconnect')
            await context.dispatch('refresh_session')
            const url = new URL(window.location.href)
            url.searchParams.delete('orgId')
            window.location.href = url.toString()
        },

        refresh_session(context) {
            return new Promise((resolve, reject) => {
                api.get('@@session-info')
                    .then(response => {
                        context.commit('set_session_info', response.data)
                        api.defaults.headers.common['X-CSRF-Token'] = response.data.csrf_token;
                        resolve(response);
                    })
                    .catch(error => {
                        context.commit('set_application_fault',
                            "Connecting to PayPredict server resulted in the following error: " + error.message)
                        reject(error)
                    })
            })
        },
        init_account(context, settings) {

            // Session data not ready till refresh it since initing some fields for first time
            context.commit('set_session_dirty')

            // Init account and refresh session once done
            return api.post('crisko-users/me/@@init-account', settings).then(
                () => context.dispatch('refresh_session'))
        },
        update_settings(context, settings) {
            return api.post('crisko-users/me/@@update-settings', settings).then(
                () => context.dispatch('refresh_session'))
        },
        set_receive_alerts(context, receive_alerts) {
            return new Promise((resolve, reject) => {
                api.post('crisko-users/me/@@set-receive-alerts', { 'receive_alerts': receive_alerts })
                    .then(() => {
                        context.dispatch('refresh_session');
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        set_automated_reminders(context, automated_reminders) {
            return new Promise((resolve, reject) => {
                api.post('crisko-users/me/@@set-automated-reminders', { 'automated_reminders': automated_reminders })
                    .then(() => {
                        context.dispatch('refresh_session');
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },

        async reload_authed_xero(context) {

            let response = await api.get('integrations/authed-xero/' + context.getters.current_xero_organisation)
            context.commit('set_authed_xero', response.data.data);

            if ((context.getters.finished_syncing) && (context.state.authed_xero.current_report_id == null)) {
                // trigger a sync
                context.dispatch('resync_and_reload_contacts_report')
                return
            }

            while (!context.getters.finished_syncing) {

                // small delay
                await new Promise(resolve => setTimeout(resolve, 1000));

                let response = await api.get('v2/accounts/' + context.getters.current_xero_organisation + '/@@supplypredict')
                context.commit('set_authed_xero', response.data.data);
            }
        },

        reload_contacts_report(context) {
            context.commit('set_is_fetching_contacts_report', true);

            return new Promise((resolve, reject) => {
                api.get('integrations/authed-xero/' + context.getters.current_xero_organisation + '/@@contacts-report')
                    .then((response) => {
                        context.commit('set_contacts_report', response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    }).finally(() => {
                        context.commit('set_is_fetching_contacts_report', false);
                    })
            })
        },

        async resync_and_reload_contacts_report(context) {
            // Trigger a resync of Xero data on backend and reload contacts report when done

            // Trigger resync
            await api.post('integrations/authed-xero/' + context.getters.current_xero_organisation + '/@@trigger-resync')

            // Reload authed_xero and contacts_report
            await context.dispatch('reload_authed_xero')
            await context.dispatch('reload_contacts_report')

        },

        send_email_via_pyramid(context, settings) {
            return new Promise((resolve, reject) => {
                const subject = 'Message from ' + settings['name']
                api.post('@@send-message', {
                    'subject': subject,
                    'reply_to': settings['email'],
                    'body': settings['message'],
                })
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })

            })
        },

        set_share_organisation(context) {
            context.commit("set_share_organisation", new URLSearchParams(window.location.search))
        },

        get_users_connected_accounts(context) {
            return new Promise((resolve, reject) => {
                api.get('/v2/@@session')
                    .then(response => {
                        context.commit('set_user_connected_accounts', response.data.data.accounts || [])
                        resolve();
                    })
                    .catch(error => {
                        context.commit('set_application_fault',
                            "Connecting to PayPredict server resulted in the following error: " + error.message)
                        reject(error)
                    })
            })
        }
    },


})