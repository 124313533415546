<template>
  <div v-if="showSignUpForm">
    <p>
      <label>Name:</label>
      <input v-model="name" type="text" class="form-control">
    </p>
    <p class="error_msg" v-if="name_error">{{ name_error }}</p>
    <p>
      <label>Email:</label>
      <input v-model="email" type="email" class="form-control">
    </p>
    <p class="error_msg" v-if="email_error">{{ email_error }}</p>

    <p>
      <label>Password:</label>
      <input v-model="password" type="password" class="form-control">
    </p>
    <p class="error_msg" v-if="password_length_check">
      Password must be at least 12 characters
    </p>
    <p class="error_msg" v-else-if="password_error">{{ password_error }}</p>

    <p>
      <label>Repeat Password:</label>
      <input v-model="password2" type="password" class="form-control">
    </p>
    <p class="error_msg" v-if="password2_error">{{ password2_error }}</p>

    <button
      class="btn btn-primary btn-block"
      @click="check_signup_inputs"
      :disabled="loading"
    >
      <span class="ml-3">
        <strong>{{ button_text }}</strong>
      </span>
    </button>

    <a href="#" @click="exit">
      <p>Use a different sign up method</p>
    </a>

    <p>
      Your name and email matter, make sure we know who you are and how we can alert you of any supplier issues that arise.
    </p>
  </div>
  <business-details v-else-if="showBusinessDetails"/>
</template>
<script>
import api from "@/api.js";
import BusinessDetails from './BusinessDetails.vue';

export default {
  name: "emailLogin",
  components: {
    BusinessDetails
  },
  data() {
    return {
      showSignUpForm: true,
      showBusinessDetails: false,
      loading: false,
      name: "",
      name_error: null,
      email: "",
      email_error: null,
      password: "",
      password_error: null,
      password2: "",
      password2_error: null,
    };
  },
  computed: {
    button_text(){
      if (this.loading) {
        return "Loading..."
      }
      return "Sign Up"
    },
    password_length_check() {
      return (this.password.length > 0 && this.password.length < 12)
    }
  },
  methods: {
    check_signup_inputs() {
      const hasError = false;
      this.showSignUpForm = false;
      this.showBusinessDetails = true;

      // Clear error messages
      this.email_error = null;
      this.name_error = null;
      this.password_error = null;
      this.password2_error = null;

      // // Check inputs
      if (this.name == "") {
        this.name_error = "This field is required";
        hasError = true;
      }

      if (this.email == ""){
        this.email_error = "This field is required"
        hasError = true;
      }

      if (this.password == "") {
        this.password_error = "This field is required"
        hasError = true;
      } else if (this.password.length < 12) {
        this.password_error = "Password must be at least 12 characters"
        hasError = true;
      } else if (this.password2 != this.password) {
        this.password2_error = "Passwords must match"
        hasError = true;
      }

      if (!hasError) {
        this.signup()
      }
    },
    signup() {
      // Make signup api request
      this.loading = true
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'signup',
          app_action_category: 'signup',
          app_action_label: 'password',
      })

      api.post('@@signup', {email: this.email, password: this.password, name: this.name, newsletter_optin: false})
      .then(resp => {
        // Sign in using new credentials
        api.post(
          '@@login',
          null,
          {headers: {
            'Authorization': "Basic " + btoa(this.email + ":" + this.password)
          }}
        )
        .then(resp2 => {
          this.showSignUpForm = false;
          this.showBusinessDetails = true;
          // document.location = "/" // Refresh page to use new login details
        })
      })
      .catch(error => {
        // Communicate reason sign up failed
        if (error.response.data.message == "Missing email field") {
          this.email_error = "This field is required"
        } else if (error.response.data.message == "Invalid email") {
          this.email_error = "Please enter a valid email"
        } else if (error.response.data.message == "Email already in use") {
          this.email_error = "Email already in use"
        } else if (error.response.data.message) {
          // Remaining errors are password complexity errors
          this.password_error = error.response.data.message
        } else {
          // In case of 500 error/network issue
          this.password_error = "Sign up failed"
        }
      })
      .then(() => this.loading = false)
    },
    exit() {
      this.$emit("exit")
    }
  },
};
</script>
<style type="text/css" scoped>
.error_msg {
  color: #000;
  background-color: #FFBABA;
  border: 1px solid;
  padding: 15px;
}
</style>
