<template>
  <div>
    <email-sign-up
      v-if="sign_up"
      v-on:exit="sign_up=false"
    />
    <email-login
      v-else-if="login"
      v-on:exit="login=false"
      v-on:switch_to_signup="switch_to_signup"
    />

    <div v-else-if="create_account"
         v-on:exit="create_account=false"  class="d-grid gap-2">
      <h2>Create an account</h2>
      <p class="mb-4"> Already have an account? <a href="#" @click="create_account=false">Login</a></p>
      <p v-if="signup_title != ''" class="mb-4">{{ signup_title }}</p>
      <label v-if="this.signup_referral_id">Referrer ID: {{ this.signup_referral_id }}</label>
      <button
        class="btn btn-secondary"
        @click="GoogleLogin()"
        style="height: 50px;"
      >
        <img id="circle-white-background" src="https://evenly.com.au/storage/app/media/app/google-login.png" />
        <span class="ml-3">
          Sign up with Google
        </span>
      </button>
      <button
        class="btn btn-secondary"
        @click="sign_up=true"
        style="height: 50px;"
      >
        <span class="ml-3">
          Sign up with Email
        </span>
      </button>
     
    </div>
    <div v-else  class="d-grid gap-2">
      <h2>Sign in</h2>
      <p class="mb-4">Need an account? <a href="#" @click="create_account=true">Sign up</a></p>

      <button
        class="btn btn-secondary"
        @click="GoogleLogin()"
        style="height: 50px;"
      >
        <img id="circle-white-background" src="https://evenly.com.au/storage/app/media/app/google-login.png" />
        <span class="ml-3">
          Login with Google
        </span>
      </button>
       <button
        class="btn btn-secondary"
        @click="login=true"
        style="height: 50px;"
      >
        <span class="ml-3">
          Login with Email
        </span>
      </button>

    </div>
  </div>
</template>

<script>
import EmailSignUp from "@/components/EmailSignUp.vue";
import EmailLogin from "@/components/EmailLogin.vue";
export default {
  name: "referlogin",
  computed: {
    signup_type() {
      return this.$store.state.signup_type;
    },
    signup_referral_id() {
      return this.$store.state.signup_referral_id;
    },
    signup_title() {
      if (["advisor", "referred_by_advisor"].includes(this.signup_type)) {
        return "Sign up to PayPredict for free, forever! Thanks to your advisor.";
      }
      return "";
    },
  },
  components: {
    EmailSignUp,
    EmailLogin,
  },
  data() {
    return {
      sign_up: false,
      login: false,
      create_account: false,
    };
  },
  methods: {
    GoToLogin() {
      location.assign("/login/xero?intent=suppliercheck");
    },
    GoogleLogin() {
      location.assign("/login/google?intent=suppliercheck");
    },
    switch_to_signup() {
      this.login = false;
      this.sign_up = true;
    },
  },
  mounted() {
      this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  },
};
</script>
<style type="text/css" scoped>
#circle-white-background {
  background: white;
  padding: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
</style>
