<template>
  <div>
    <p>
      <label>Email:</label>
      <input v-model="email" type="email" class="form-control">
    </p>
    <p class="error_msg" v-if="email_error">{{ email_error }}</p>
    <p>
      <label>Password:</label>
      <input v-model="password" type="password" class="form-control">
    </p>
    <p class="error_msg" v-if="password_error">
      {{ password_error }}
      <a v-if="show_signup_link" @click="switch_to_signup">HERE</a>
    </p>
    <button
      class="btn btn-primary btn-block"
      @click="login"
      :disabled="loading"
    >
      <span class="ml-3">
        <strong>{{ buttonText }}</strong>
      </span>
    </button>
    <a href="#" @click="reset">
      <p style="padding-top: 12px;">Forgot Password</p>
    </a>
    <a href="#" @click="exit">
      <p>Use a different sign in method</p>
    </a>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  name: "emailLogin",
  data() {
    return {
      loading: false,
      email: "",
      email_error: null,
      password: "",
      password_error: null,
      show_signup_link: false,
    };
  },
  computed: {
    buttonText (){
      if (this.loading) {
        return "Loading..."
      }
      return "Login"
    }
  },
  methods: {
    login() {
      // Reset error messages
      this.email_error = null
      this.password_error = null
      this.show_signup_link = false

      // Check email and password
      if (this.email == ""){
        this.email_error = "This field is required"
        return
      }
      if (this.password == ""){
        this.password_error = "This field is required"
        return
      }

      // Attempt to login
      this.loading = true
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'login',
          app_action_category: 'login',
          app_action_label: 'password',
      })

      api.post(
        '@@login',
        null,
        {headers: {
          'Authorization': "Basic " + btoa(this.email + ":" + this.password)
        }}
      )
      .then(resp => document.location = "/sc")
      .catch(() => this.password_error = "Invalid email/password.")
      .then(() => this.loading = false)
      // Todo deal with login failure
    },
    reset() {
      // Reset error messages
      this.email_error = null
      this.password_error = null
      this.show_signup_link = false

      // Check email
      if (this.email == ""){
        this.email_error = "Please enter your email to reset your password"
        return
      }

      // Attempt to reset password
      this.loading = true
      api.post('@@reset', {email: this.email})
      .then(resp => {
        this.password_error = "Check your inbox to complete password reset."
      })
      .catch(error => {
        this.show_signup_link = true
        this.password_error = "We can't find an account with that email address. Please Sign Up" // link appears after
      })
      .then(() => this.loading = false)
    },
    exit() {
      this.$emit("exit")
    },
    switch_to_signup() {
      this.$emit("switch_to_signup")
    }
  },
};
</script>
<style type="text/css" scoped>
.error_msg {
  color: #000;
  background-color: #FFBABA;
  border: 1px solid;
  padding: 15px;
}
</style>
