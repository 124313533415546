<template>
  <div>
    <div
      
      class="app-main"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
//import { mapGetters } from "vuex";

export default {
  name: "appmain",
  // computed: {
  //   ...mapGetters(["xero_is_connected"]),
  //   xero_authed_organisation_id() {
  //     return this.$store.state.session_info.xero_authed_organisation_id;
  //   },
  //   share_organisation_id() {
  //     return this.$store.state.share_organisation.xero_authed_organisation_id;
  //   },
  // },

  // methods: {
  //   GoToLogin() {
  //     location.assign("/login/xero");
  //   },
  // },
};
</script>

