<template>
  <div>
    <h2 class="mt-0">Your business details</h2>
    <p class="mb-4">Please enter your business name and ABN.</p>

    <p>
      <label>Business name <span class="text-danger">*</span>:</label>
      <input v-model="business_name" type="text" class="form-control" autofocus>
    </p>
    <p class="error_msg" v-if="business_name_error">{{ business_name_error }}</p>
    <p>
      <label>ABN <span class="text-danger">*</span>:</label>
      <input v-model="abn" type="text" class="form-control">
    </p>
    <p class="error_msg" v-if="abn_error">{{ abn_error }}</p>

    <div class="my-2">
      <label>Are you an Equifax customer? <span class="text-danger">*</span></label>
      <div class="form-check">
        <input class="form-check-input" v-model="efx_customer" name="efx_customer" type="radio" value="1" id="efx_yes">
        <label class="form-check-label" for="efx_yes">
          Yes
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="efx_customer" name="efx_customer" type="radio" value="0" id="efx_no">
        <label class="form-check-label" for="efx_no">
          No
        </label>
      </div>
    </div>

    <template v-if="efx_customer == 1">
      <p>
        <label>What is your Equifax Customer Number:</label>
        <input v-model="efx_number" type="text" class="form-control">
        <div class="form-check">
          <input class="form-check-input" v-model="efx_unknown" type="checkbox" id="efx_unknown">
          <label class="form-check-label" for="efx_unknown">
            Not Sure
          </label>
        </div>
        <p class="error_msg" v-if="efx_number_error">{{ efx_number_error }}</p>
      </p>
    </template>

    <div class="row my-4" v-if="error_message">
      <div class="alert alert-danger" role="alert">
        {{ error_message }}
      </div>
    </div>

    <button
      class="btn btn-primary btn-block my-2"
      @click="check_signup_inputs"
      :disabled="loading"
    >
      <span class="ml-3">
        <strong>{{ button_text }}</strong>
      </span>
    </button>

    <div class="form-check mt-5">
      <input class="form-check-input" v-model="keep_me_posted" type="checkbox" id="keep_me_posted">
      <label class="form-check-label" for="keep_me_posted">
        keep me posted as SupplyPredict launches new feature to help my business.
      </label>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "BusinessDetails",
  data() {
    return {
      efx_customer: "0",
      loading: false,

      business_name: '',
      business_name_error: null,
      abn: '',
      abn_error: null,
      efx_number: '',
      efx_number_error: null,
      efx_unknown: false ,
      keep_me_posted: true,
      error_message: null,
    };
  },
  computed: {
    button_text(){
      if (this.loading) {
        return "Saving..."
      }
      return "Save and Continue"
    },
    password_length_check() {
      return (this.password.length > 0 && this.password.length < 12)
    }
  },
  methods: {
    check_signup_inputs() {
      let hasError = false;

      // Clear error messages
      this.business_name_error = null;
      this.abn_error = null;
      this.efx_number_error = null;

      // Check inputs
      if (this.business_name == "") {
        this.business_name_error = "This field is required";
        hasError = true;
      }

      if (this.abn == "") {
        this.abn_error = "This field is required";
        hasError = true;
      }

      if (this.efx_number == "" && this.efx_customer == 1 && !this.efx_unknown) {
        this.efx_number_error = "This field is required";
        hasError = true;
      }

      if (!hasError) {
        this.saveBusinessDetails()
      }
    },
    saveBusinessDetails() {
      this.error_message = null;
      const apiRequests = [
        api.post('v2/accounts',
          {
            display_name: this.business_name,
            abn: this.abn,
            equifax_customer_number: this.efx_number,
            jurisdiction: "AU",
            payment_plan: "supplypredict",
          }),

        // this.$store.dispatch("init_account", {
        //   name: this.$store.state.session_info.crisko_user_info.name,
        //   email: this.$store.state.session_info.crisko_user_info.email,
        //   newsletter_optin: this.keep_me_posted
        // })
      ];

      axios.all(apiRequests).then(axios.spread((...responses) => {
        document.location = `/sp?orgId=${responses[0].data.data.account_id}` // Refresh page to use new login details
      })).catch((error) => {
        if (error.response.data.message = 'Bad Request: Invalid abn') {
          this.error_message = 'Invalid ABN, please check!';
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500)
      });
    },
    exit() {
      this.$emit("exit")
    }
  },
};
</script>
<style type="text/css" scoped>
.error_msg {
  color: #000;
  background-color: #FFBABA;
  border: 1px solid;
  padding: 15px;
}
</style>
